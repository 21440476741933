
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

import DocsBeta from 'src/components/docs/DocsBeta/index.tsx';

export const metadata = {
  feedback: true,
  toc: true,
  layout: 'reference',
  description: 'Help and support for your Plaid integration, including ways to contact us'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Header title='Plaid help and support' subtitle='Get help for your Plaid integration' mdxType="Header" />
    <h4 {...{
      "id": "consumer-support"
    }}>{`Consumer support`}</h4>
    <p>{`If you are using Plaid to connect your financial accounts and have questions, use the links below for more information:`}</p>
    <p>{`To learn more about Plaid and how it works, see our page on `}<a parentName="p" {...{
        "href": "https://plaid.com/how-it-works-for-consumers/"
      }}>{`How Plaid Works`}</a>{`.`}</p>
    <p>{`If you are having trouble linking your financial institution, see our `}<a parentName="p" {...{
        "href": "https://support-my.plaid.com/hc/en-us/categories/4405983222679-Connecting-Financial-Accounts"
      }}>{`Connecting Financial Accounts`}</a>{` Guide on the `}<a parentName="p" {...{
        "href": "https://support-my.plaid.com/hc/en-us"
      }}>{`Plaid Help Center`}</a>{` for more information. If you have questions about linking through a specific Plaid-powered app, we recommend reaching out to the app directly for assistance.`}</p>
    <h5 {...{
      "id": "managing-or-disconnecting-your-plaid-linked-bank-accounts"
    }}>{`Managing or disconnecting your Plaid linked bank accounts`}</h5>
    <p>{`Manage Plaid's access to your financial data via the `}<a parentName="p" {...{
        "href": "https://my.plaid.com/"
      }}>{`Plaid Portal`}</a>{` at `}<a parentName="p" {...{
        "href": "https://my.plaid.com/"
      }}>{`https://my.plaid.com/`}</a>{`. You can use the portal to review your current connections, disconnect financial accounts from an app, or delete your data from Plaid's systems.`}</p>
    <h5 {...{
      "id": "privacy-and-security-information-for-consumers"
    }}>{`Privacy and security information for consumers`}</h5>
    <p>{`For more information on Plaid's privacy and security practices, see `}<a parentName="p" {...{
        "href": "https://plaid.com/how-we-handle-data/"
      }}>{`How we handle data`}</a>{`.`}</p>
    <h4 {...{
      "id": "developer-support"
    }}>{`Developer support`}</h4>
    <p>{`If you are a developer building with Plaid, the answers to most questions can be found in the docs -- try using the search feature, or try `}<a parentName="p" {...{
        "href": "https://plaid.com/docs/support/?showChat=true"
      }}>{`"Ask Bill" within the search menu`}</a>{` to ask our friendly chatbot for help!`}</p>
    <p>{`Troubleshooting details for specific errors or failed requests can be found in the Dashboard:`}</p>
    <ul>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://dashboard.plaid.com/activity/status"
        }}>{`Institution status page`}</a>{` provides details on institution support, downtime, and connectivity.`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://dashboard.plaid.com/developers/logs"
        }}>{`logs page`}</a>{` provides details on specific requests.`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://dashboard.plaid.com/activity/debugger"
        }}>{`Item Debugger`}</a>{` provides details on specific Items or Link sessions.`}</li>
    </ul>
    <p>{`If you do not have a Dashboard account, you can ask the team member who manages your Plaid team to `}<a parentName="p" {...{
        "href": "/docs/account/teams/#managing-team-member-permissions"
      }}>{`invite you`}</a>{` to the team.`}</p>
    <p>{`For general questions about using Plaid APIs or your integration you can also ask the `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/tagged/plaid"
      }}>{`Plaid community on Stack Overflow`}</a>{` or `}<a parentName="p" {...{
        "href": "https://discord.gg/sf57M8DW3y"
      }}>{`Discord`}</a>{`.`}</p>
    <p>{`If you can't find an answer to your question, `}<a parentName="p" {...{
        "href": "https://dashboard.plaid.com/support"
      }}>{`file a Support ticket`}</a>{` via your Dashboard account. To file a support ticket, you must have Production access; if you do not already have it, complete the `}<a parentName="p" {...{
        "href": "https://dashboard.plaid.com/overview/production"
      }}>{`Production request flow`}</a>{`. You will also need the `}<a parentName="p" {...{
        "href": "/docs/account/teams/#managing-team-member-permissions"
      }}>{`Support permission`}</a>{` on your team; if you don't have it, contact your team administrator for help.`}</p>
    <h4 {...{
      "id": "core-exchange-and-plaid-exchange-documentation-and-support"
    }}>{`Core Exchange and Plaid Exchange documentation and support`}</h4>
    <p>{`If you are a developer at a bank or other financial institution looking for information about APIs used to provide consumer-permissioned data to Plaid, help and documentation for the Core Exchange and Plaid Exchange APIs can be found on their dedicated sites:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://plaid.com/core-exchange/docs"
        }}>{`Core Exchange API documentation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://plaid.com/exchange/docs/"
        }}>{`Plaid Exchange API documentation`}</a></li>
    </ul>
    <h4 {...{
      "id": "prospective-customer-support"
    }}>{`Prospective customer support`}</h4>
    <p>{`If you have a question about Plaid capabilities that isn't answered in the docs, or for details about pricing, `}<a parentName="p" {...{
        "href": "https://plaid.com/contact/"
      }}>{`contact Sales`}</a>{` to learn more.`}</p>
    <h4 {...{
      "id": "no-code-support"
    }}>{`No code support`}</h4>
    <p>{`If you're looking for a third party who can build a Plaid integration for you, rather than building one yourself, `}<a parentName="p" {...{
        "href": "https://dashboard.plaid.com/support/new/product-and-development/developer-lifecycle/developer-resources"
      }}>{`contact Support`}</a>{` and select "Building with Plaid" followed by "Plaid Partner Implementation Assistance" and we can help you find a Plaid integrator to meet your needs. Or, contact your Account Manager for details.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;

            export const tableOfContents = [
      {
        id: "consumer-support",
        level: 4,
        title: "Consumer support",
      },
{
        id: "developer-support",
        level: 4,
        title: "Developer support",
      },
{
        id: "core-exchange-and-plaid-exchange-documentation-and-support",
        level: 4,
        title: "Core Exchange and Plaid Exchange documentation and support",
      },
{
        id: "prospective-customer-support",
        level: 4,
        title: "Prospective customer support",
      },
{
        id: "no-code-support",
        level: 4,
        title: "No code support",
      }
];
          
layoutProps.tableOfContents = tableOfContents;
MDXContent.layoutProps = layoutProps;

MDXContent.layout = function (props) { return <DocsBeta {...props} /> };
